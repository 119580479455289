import {
  Button,
  Flex,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorMode,
} from "@chakra-ui/react";
import type {
  GetStaticProps as GSP,
  NextPage,
} from "next";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
//import DashboardNav from "../components/DashboardNav";
import {
  currentUserDataState,
  isEmployeeState,
  isLoggedInState,
} from "../recoilStates";

const Home: NextPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [currentUserData, setCurrentUserData] =
    useRecoilState(currentUserDataState);

  const [isEmployee, setIsEmployee] = useRecoilState(isEmployeeState);
  const router = useRouter();
  useEffect(() => {
    if (!isLoggedIn) {
      router.push("/sign-in");
    } else {
      // setTotalGraphData(dashboardData);
      if (isEmployee) {
        router.replace(`/register?businessId=${currentUserData.PersonId}`);
      } else
        router.replace(
          `/dashboard/${currentUserData.GUID}/?businessId=${currentUserData.PersonId}`
        );
    }
  }, [isLoggedIn, isEmployee, currentUserData, router]);

  return <Flex></Flex>;
};

export default Home;
